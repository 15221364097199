<template>
    <div class="box">
        <div class="channel" style="border-radius: 8px;">
            <div class="title">
                <div class="">
                    <span style="font-size:18px;font-weight:bold;color:black">渠道统计</span> &nbsp;
                    <span>单位:元</span>
                </div>
                <div class="">
                    <a-button style="color:#FB4702;font-weight:bold;" @click="showDialog('cargoNum')">渠道选择</a-button>
                    &nbsp;
                    <a-range-picker @change="onChange" style="width:200px" format="YYYY-MM-DD HH:mm:ss"
                        v-model="channelTime" />
                </div>

            </div>
            <div id="first">
            </div>
        </div>
        <div class="center">
            <div class="channel_type" style="border-radius: 8px;">
                <div class="title">
                    <div class="">
                        <span style="font-size:18px;font-weight:bold;color:black">渠道类型统计</span> &nbsp;
                        <span>单位:元</span>
                    </div>
                    <div class="">
                        <a-range-picker @change="onChangeType" style="width:200px" v-model="channelTypeTime"
                            format="YYYY-MM-DD" />
                    </div>
                </div>
                <div id="second">
                </div>
            </div>
            <div class="country" style="border-radius: 8px;">
                <div class="title">
                    <div class="">
                        <span style="font-size:18px;font-weight:bold;color:black">目的地</span> &nbsp;
                        <span>单位:元</span>
                    </div>
                    <div class="">
                        <a-range-picker @change="onChangeCountry" style="width:200px" v-model="countryTime"
                            format="YYYY-MM-DD" />
                    </div>
                </div>
                <div id="third">
                </div>
            </div>
        </div>
        <div class="channel" style="border-radius: 8px;">
            <div class="title" style="margin-bottom: 10px;">
                <div class="">
                    <span style="font-size:18px;font-weight:bold;color:black">月度统计</span> &nbsp;
                    <span>单位:元</span>
                </div>
                <div class="">
                    <a-button style="color:#FB4702;font-weight:bold;" @click="showDialog('cargoMonth')">渠道选择</a-button>
                    &nbsp;
                    <a-range-picker :mode="mode2" @panelChange="handlePanelChange2" @change="onChangeMonths"
                        style="width:200px" format="YYYY-MM" v-model="monthsTime" />
                </div>

            </div>
            <div id="fourth">
            </div>
        </div>
        <a-modal :title="dialog.title" :visible="dialog.show" @ok="handleOk" @cancel="(dialog.show=false)">
            <div class="channel_choose">
                <div class="left">
                    <button v-for="(item,index) in channelLists" :key="index"
                        :class="{'my_button':true,'active_button':item.active==true}" @click="chooseType(item)">
                        {{item.cate_name}}</button>
                </div>
                <div class="right">
                    <button v-for="(item,index) in chooseChannelLists" :key="index"
                        :class="{'my_button':true,'active_button':item.active==true}" @click="chooseId(item)">{{
                        item.channel_name
                    }}</button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import {
        colorLists,
        colorLists2
    } from "./colorList.js"
    import {
        verticalGraph,
        portaitGraph,
        pieGraph,
        BrokenLine
    } from "./option"
    import moment from 'moment'
    import {
        costChannel,
        costChannelCate,
        channelList,
        costCountry,
        costMonth
    } from "@/api/DataStatistics"
    export default {
        name: "FreightStatistics",
        data() {
            return {
                channelLists: [],
                chooseChannelLists: [],
                chooseIdArr: [],
                channelTime: [
                    moment().subtract(3, "months"), moment(new Date().getTime())
                ],
                channelTypeTime: [
                    moment().subtract(3, "months"), moment(new Date().getTime())
                ],
                countryTime: [
                    moment().subtract(3, "months"), moment(new Date().getTime())
                ],
                monthsTime: [
                    moment().subtract(3, "months"), moment(new Date().getTime())
                ],

                moment,
                channelTypeList: [{
                    name: '全部'
                }, {
                    name: '快递'
                }, {
                    name: '海运'
                }, {
                    name: '空运'
                }, {
                    name: '铁路'
                }, {
                    name: '专车'
                }],
                dialog: {
                    show: false,
                    title: '渠道选择'
                },
                screenWidth: null,
                colorList: colorLists(this),
                colorList2: colorLists2(this),
                myChart: {
                    channel: null
                },
                form: {
                    channelIds: []
                },

                channelOptionData: {},
                channelTypeData: [
                    ['金额', '类型']
                ],
                countryData: [],
                cargoMonthChannelIds: [],
                cargoNumConfig: {},
                mode2: ['month', 'month']
            }
        },
        mounted() {
            this.showCharts()
            window.onresize = () => {
                this.myChart.channel.resize()
                this.myChart.channelType.resize()
                this.myChart.country.resize()
                this.myChart.months.resize()
            }
        },
        created() {
            channelList().then(res => {
                console.log(res.data);
                let obj = {
                    cate_name: "全部",
                    channels: [],
                    active: true
                }
                res.data.forEach(ele => {
                    obj.channels = obj.channels.concat(ele.channels)
                })
                this.chooseChannelLists = obj.channels
                res.data.unshift(obj)
                this.channelLists = res.data
            })
            this.getChannelCateData()
            this.getCountryData()
            this.getCostCargoNum()
            this.getMonthsData()
        },
        methods: {
            showDialog(type) {
                this.dialog.show = true
                this.dialog.type = type
                this.chooseIdArr = []
                switch (type) {
                    case 'cargoNum':
                        this.chooseIdArr = this.form.channelIds
                        break;
                    case 'cargoMonth':
                        this.chooseIdArr = this.cargoMonthChannelIds
                        break
                    default:
                        this.chooseIdArr = []
                        break;
                }
                for (let i of this.chooseChannelLists) {
                    i.active = false
                    if (this.chooseIdArr.includes(i.channel_id)) i.active = true
                }
            },
            chooseId(item) {
                this.chooseIdArr.includes(item.channel_id) ? this.chooseIdArr.splice(this.chooseIdArr.indexOf(item
                    .channel_id), 1) : this.chooseIdArr.push(item.channel_id)
                for (let i of this.chooseChannelLists) {
                    i.active = false
                    if (this.chooseIdArr.includes(i.channel_id)) {
                        i.active = true
                    }
                }
                this.chooseChannelLists.sort()
            },
            chooseType(item) {
                for (let i of this.channelLists) {
                    i.active = false
                    if (i.cate_name == item.cate_name) {
                        i.active = true
                        this.chooseChannelLists = i.channels
                    }
                }
                this.channelLists.sort()
            },
            onChange(date, dateString) {
                this.channelTime = date
                this.getCostCargoNum()
            },
            onChangeMonths(data, dateString) {
                this.monthsTime = data
                this.getMonthsData()
            },
            handlePanelChange2(value, mode) {
                this.monthsTime = value;
                this.mode2 = [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]];
                this.getMonthsData()
            },
            onChangeType(date, dateString) {
                this.channelTypeTime = date
                this.getChannelCateData()
            },
            onChangeCountry(date) {
                this.countryTime = date
                this.getCountryData()
            },
            getMonthsData() {

                costMonth({
                    "timeStart": moment(this.monthsTime[0]).format("YYYY-MM-DD"),
                    "timeEnd": moment(this.monthsTime[1]).format("YYYY-MM-DD"),
                    'channelIds': this.cargoMonthChannelIds
                }).then(res => {
                    console.log(res);
                    let xMsg = []
                    let objects = []
                    let data = []
                    for (let i of res.data[0].month_items) {
                        xMsg.push(i.time.slice(0, 4) + '年' + i.time.slice(4, 6) + "月")
                    }
                    for (let i of res.data) {
                        objects.push(i.channel_name)
                        let obj = {
                            name: i.channel_name,
                            type: 'line',
                            stack: 'Total',
                            data: []
                        }
                        for (let j of i.month_items) {
                            obj.data.push(Number(j.amount))
                        }
                        data.push(obj)
                    }
                    this.cargoNumConfig = {
                        xMsg,
                        objects,
                        data
                    }
                    this.showCharts()
                })


            },
            getCountryData() {
                costCountry({
                    // "timeStart": moment(this.countryTime[0]).format("YYYY-MM-DD"),
                    // "timeEnd":moment(this.countryTime[1]).format("YYYY-MM-DD")
                }).then(res => {
                    let arr = []
                    for (let i of res.data) {
                        if (i.amount != 0) {
                            arr.push({
                                value: i.amount,
                                name: i.country_name
                            })
                        }
                    }
                    this.countryData = arr
                    this.showCharts()
                })
            },
            getChannelCateData() {
                costChannelCate({
                    "timeStart": moment(this.channelTypeTime[0]).format("YYYY-MM-DD"),
                    "timeEnd": moment(this.channelTypeTime[1]).format("YYYY-MM-DD")
                }).then(res => {
                    this.channelTypeData = []
                    let arr = [
                        ['金额', '类型']
                    ]
                    res.data.forEach(ele => {
                        let li = [ele.channel_cate_name, ele.amount]
                        arr.push(li)
                    });
                    this.channelTypeData = arr
                    this.showCharts()
                })
            },
            handleOk() {
                if (this.dialog.type == 'cargoNum') {

                    this.getCostCargoNum()

                } else if (this.dialog.type == 'cargoMonth') {
                    this.cargoMonthChannelIds = this.chooseIdArr
                    this.getMonthsData()
                    this.dialog.show = false
                }

            },
            getCostCargoNum() {
                this.form.channelIds = this.chooseIdArr
                this.form.timeStart = moment(this.channelTime[0]).format("YYYY-MM-DD HH:mm:ss")
                this.form.timeEnd = moment(this.channelTime[1]).format("YYYY-MM-DD HH:mm:ss")
                costChannel(this.form).then(res => {
                    let channelOptionData = {
                        key: [],
                        value: []
                    }
                    if (res.data) {
                        res.data.forEach(ele => {
                            channelOptionData.key.push(ele.channel_name)
                            channelOptionData.value.push(ele.amount)
                        })
                        this.channelOptionData = channelOptionData
                        this.showCharts()
                    }else{
                        this.$message.info("本次查询无数据！")
                    }

                    this.dialog.show = false
                })
            },
            showCharts() {
                this.myChart.channel = this.$echarts.init(document.getElementById("first"));
                this.myChart.channelType = this.$echarts.init(document.getElementById("second"));
                this.myChart.country = this.$echarts.init(document.getElementById("third"));
                this.myChart.months = this.$echarts.init(document.getElementById("fourth"));
                //渠道统计
                this.myChart.channel.setOption(verticalGraph(this.channelOptionData, this.colorList))
                console.log(this.channelTypeData);
                this.myChart.channelType.setOption(portaitGraph(this.channelTypeData, this.colorList2))
                //等待接口
                this.myChart.country.setOption(pieGraph(this.countryData))
                this.myChart.months.setOption(BrokenLine(this.cargoNumConfig.xMsg || [], this.cargoNumConfig.objects ||
                    [], this.cargoNumConfig.data || []))
            },

        },
    }
</script>

<style lang="less" scoped>
    .channel_choose {
        display: flex;

        .left {
            width: 80px;
            border-right: 1px solid #f5f5f5;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-bottom: 15px;
            padding-right: 15px;

            .my_button {
                background: #f4f9fd;
                color: #808282;
                border: none;
                width: 60px;
                border-radius: 4px;
                margin-top: 15px;
                text-align: center;
                border: 1px solid transparent;
            }

            .my_button:hover {
                color: #fa4800;
            }

            .active_button {
                background: #fffbfa;
                color: #fe822f;
                border: 1px solid #ffe1cd;
            }
        }

        .right {
            padding: 0 15px;
            height: 250px;
            overflow-y: auto;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;

            .my_button {
                background: #f4f9fd;
                color: #808282;
                border: none;
                border-radius: 4px;
                margin-top: 15px;
                height: 25px;
                text-align: center;
                border: 1px solid transparent;
            }

            .my_button:hover {
                color: #fa4800;
            }

            .active_button {
                background: #fffbfa;
                color: #fe822f;
                border: 1px solid #ffe1cd;
            }

            button {
                margin-left: 10px;
                margin-top: 10px;
            }
        }
    }

    .box {
        background: #f0f2f5;


        .btns {
            width: 300px;
            display: flex;
            justify-content: space-between;
        }

        .channel {
            background: #ffffff;
            width: 100%;
            padding: 10px;
            margin-top: 15px;

            #first {
                width: 100%;
                min-height: 500px;
            }

            #fourth {
                width: 100%;
                min-height: 500px;
            }
        }

        .center {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-top: 15px;

            .channel_type {
                width: 49.5%;
                padding: 10px;
                background: white;
                min-height: 500px;

                #second {
                    width: 100%;
                    height: 90%;
                }
            }

            .country {
                width: 49.5%;
                background: white;
                padding: 10px;
                min-height: 500px;

                #third {
                    width: 100%;
                    height: 90%;
                }
            }
        }

        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: 10px;
        }
    }

    /deep/.ant-modal-body {
        padding: 0 12px;
    }
</style>