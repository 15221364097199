export let verticalGraph = (data, colorList) => {
    return {
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow' 
            }
        },
        legend: {},
        axisLabel: {
            formatter: function (value) {
                var res = value;
                if (res.length > 5) {
                    res = res.substring(0, 4) + "..";
                }
                return res;
            }
        },
        dataZoom: [
            {
                show: true,
                realtime: true,
                start: 65,
                end: 85
            },
            {
                type: 'inside',
                realtime: true,
                start: 65,
                end: 85
            }
        ],
        xAxis: {
            type: 'category',
            data: data.key,
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'dashed'
                }
            },
            axisLine: {
                lineStyle: {
                    type: 'dashed',
                    color: '#eceff6'
                }
            },
            axisLabel: {
                color: '#333333'
            }
        },
        yAxis: {
            type: 'value',
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'dashed'
                }
            }
        },
        series: [{
            data: data.value,
            barMaxWidth:'40',
            type: 'bar',
            itemStyle: {
                normal: {
                    label: {
                        show: true,
                        position: 'top'
                    },
                    borderRadius: [8, 8, 0, 0],
                    color: (params) => {
                        return colorList[params.dataIndex % colorList.length]
                    }

                }
            }
        }]
    }
}

export let portaitGraph = (data, colorList) => {
    return {
        dataset: {
            source: data
        },
        grid: {
            containLabel: true
        },
        xAxis: {
            name: 'KG',
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'dashed'
                }
            }
        },
        yAxis: {
            type: 'category',
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'dashed'
                }
            },
            axisLine: {
                lineStyle: {
                    type: 'dashed',
                    color: '#eceff6'
                }
            },
            axisLabel: {
                color: '#333333'
            }
        },

        series: [{
            type: 'bar',
            itemStyle: {
                normal: {
                    label: {
                        show: true,
                        position: 'right'
                    },
                    borderRadius: [0, 8, 8, 0],
                    color: (params) => {
                        // color: 
                        return colorList[params.dataIndex]
                    }

                }
            },
            //设置x,y显示字段
            encode: {
                // Map the "amount" column to X axis.
                x: '类型',
                // Map the "product" column to Y axis
                y: '金额'
            }
        }]
    }
}

export const pieGraph = (data = "") => {
    return {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left',
            top: 'bottom',
        },
        series: [{
            type: 'pie',
            avoidLabelOverlap: false,
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2
            },
            data: data || [{
                    value: 1048,
                    name: 'Search Engine'
                },
                {
                    value: 735,
                    name: 'Direct'
                },
                {
                    value: 580,
                    name: 'Email'
                },
                {
                    value: 484,
                    name: 'Union Ads'
                },
                {
                    value: 300,
                    name: 'Video Ads'
                }
            ]
        }]
    }
}

export const BrokenLine = (xMsg = '', objects = '', data = '') => {
    return {
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: objects || ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
            itemGap:2,
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xMsg || ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'dashed'
                }
            },
            axisLine: {
                lineStyle: {
                    type: 'dashed',
                    color: '#eceff6'
                }
            },
            axisLabel: {
                color: '#333333'
            }
        },
        yAxis: {
            type: 'value',
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'dashed'
                }
            }
        },
        series: data || [{
                name: 'Email',
                type: 'line',
                stack: 'Total',
                data: [120, 132, 101, 134, 90, 230, 210]
            },
            {
                name: 'Union Ads',
                type: 'line',
                stack: 'Total',
                data: [220, 182, 191, 234, 290, 330, 310]
            },
            {
                name: 'Video Ads',
                type: 'line',
                stack: 'Total',
                data: [150, 232, 201, 154, 190, 330, 410]
            },
            {
                name: 'Direct',
                type: 'line',
                stack: 'Total',
                data: [320, 332, 301, 334, 390, 330, 320]
            },
            {
                name: 'Search Engine',
                type: 'line',
                stack: 'Total',
                data: [820, 932, 901, 934, 1290, 1330, 1320]
            }
        ]
    }
}