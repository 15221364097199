/*
 * @Author: your name
 * @Date: 2021-12-01 15:54:41
 * @LastEditTime: 2021-12-06 13:56:30
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/api/Waybill/index.js
 */

import httpService from "@/request"


export function quantityChannel(params) {
    return httpService({
        url: `/statistic/quantity/channel`,
        method: 'post',
        data: params,
    })
}

export function channelCate(params) {
    return httpService({
        url: `/statistic/quantity/channel/cate`,
        method: 'post',
        data: params,
    })
}

export function channelList(params) {
    return httpService({
        url: `/channel/cate/channel/list`,
        method: 'post',
        data: params,
    })
}
export function quantityCountry(params) {
    return httpService({
        url: `/statistic/quantity/country`,
        method: 'post',
        data: params,
    })
}

export function quantityMonth(params) {
    return httpService({
        url: `/statistic/quantity/month`,
        method: 'post',
        data: params,
    })
}

//运费统计
export function costChannel(params) {
    return httpService({
        url: `/statistic/cost/channel`,
        method: 'post',
        data: params,
    })
}

export function costChannelCate(params) {
    return httpService({
        url: `/statistic/cost/channel/cate`,
        method: 'post',
        data: params,
    })
}

export function costCountry(params) {
    return httpService({
        url: `/statistic/cost/country`,
        method: 'post',
        data: params,
    })
}

export function costMonth(params) {
    return httpService({
        url: `/statistic/cost/month`,
        method: 'post',
        data: params,
    })
}

export function timelinessChannel(params) {
    return httpService({
        url: `/statistic/timeliness/channel`,
        method: 'post',
        data: params,
    })
}