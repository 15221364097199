export  function colorLists(that) {
    let clist = [
        ["#1d74ff", "#ffffff"],
        ["#ff7f31", "#ffffff"],
        ["#953eff", "#ffffff"],
        ["#cad66a", "#ffffff"],
        ["#ff7926", "#ffffff"],
        ["#856991", "#ffffff"],
        ["#2bdef1", "#ffffff"],
        ["#64b84e", "#ffffff"],

    ]
    return clist.map(ele => {
            return new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: ele[0]
                },
                {
                    offset: 1,
                    color: ele[1]
                },
            ])
        })

}

export  function colorLists2(that) {
    let clist = [
        ["#1d74ff", "#ffffff"],
        ["#ff7f31", "#ffffff"],
        ["#953eff", "#ffffff"],
        ["#cad66a", "#ffffff"],
        ["#ff7926", "#ffffff"],
        ["#856991", "#ffffff"],
        ["#2bdef1", "#ffffff"],
        ["#64b84e", "#ffffff"],

    ]
    return clist.map(ele => {
            return new that.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                    offset: 0,
                    color: ele[0]
                },
                {
                    offset: 1,
                    color: ele[1]
                },
            ])
        })

}